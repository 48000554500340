import { PanelType} from "@fluentui/react"
import { IAppWindowBaseProps } from "../lib/ds/components/AppWindow"
import { PanelWithDefaultFooter } from "../lib/components/DetailsListHelper/PanelWithDefaultFooter"
import { NavigationalDetailsListWithStandardOperations } from "../lib/components/DetailsListHelper/NavigationalDetailsListWithStandardOperations"
import { ISdkCustomerDiscountProfile } from "../lib/sdk/models/ISdkCustomerDiscountProfile"
import { IColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder"
import { useEffectAsync } from "../lib/components/Helpers/EffectAsyncHook"
import { useState } from "react"
import { SdkCustomerClient } from "../lib/sdk/SdkCustomerClient"
import { useAuthInContext } from "@mevodo/mv-react-authentication"
import { ISdkCustomerDetails } from "../lib/sdk/models/ISdkCustomer"
import { SdkCustomerDiscountProfileClient } from "../lib/sdk/SdkCustomerDiscountProfileClient"

export interface IShowEffectiveDiscountProfilesDialog extends IAppWindowBaseProps {   
    tenantId: string
    itemId?: string    
}

export const ShowEffectiveDiscountProfilesDialog = (props: IShowEffectiveDiscountProfilesDialog) => {

    const auth = useAuthInContext(props.tenantId);           
    
    const [isPreparing, setIsPreparing] = useState<boolean>(false)        
    const [loadedCustomer, setLoadedCustomer] = useState<ISdkCustomerDetails>()
        
    useEffectAsync(async () => {
        
        // do nothing when no item set
        if (!props.itemId) { return }

        // set the loading mode
        setIsPreparing(true)

        // load the customer model including details
        const sdkClient = new SdkCustomerClient(props.tenantId, auth.currentToken as string)
        const customer = await sdkClient.getCustomer(props.itemId)
        setLoadedCustomer(customer)
        
        // disable the loading mode
        setIsPreparing(false)

    }, [props.itemId])
    
    const dissmissDialog = () => {
        if (props.dismissDialog)
            props.dismissDialog()
    }

    const buildColumns = (builder: IColumnsBuilder<ISdkCustomerDiscountProfile>): void  => {        
        builder.IconColumn({name: "", headerIcon: 'faPercent', iconName: (item) => {
            if (item.profileType === 'Uplift')
                return 'faArrowUp'
            else
                return 'faArrowDown'                
        }})
        builder.TextColumn({name: "Name", value: 'name', maxWidth: 250 })            
        builder.TextColumn({name: "Id", value: 'id', maxWidth: 250 })            
        builder.TextColumn({name: "Type", value: 'profileType', maxWidth: 50})       
        builder.TextColumn({name: "Details", value: (item) => {            
            return '#' + item.selectorRules.length.toFixed(0) + ' filter rule' + (item.selectorRules.length === 1 ? '' : 's') +', #' + item.items.length.toFixed(0) + ' discount/uplift item' + (item.items.length === 1 ? '' : 's')
        }, maxWidth: 250})                                
    }

    const reloadData = async (): Promise<ISdkCustomerDiscountProfile[]> => {

        const sdkClient = new SdkCustomerDiscountProfileClient(props.tenantId, auth.currentToken as string)
        const profiles = await sdkClient.listEffectiveDiscountProfiles(props.itemId as string, 'Customer')
        return profiles
    }

    return (
        <PanelWithDefaultFooter        
            isOpen={props.isVisible}
            headerText={'Effective Discount/Uplift profiles'}
            onDismiss={dissmissDialog}                                    
            type={PanelType.large}
            progessMessage={''}
            submitLabel={'Close'}
            noDissmissButton={true}
            dissmissLabel={'Cancel'}                        
            isValid={true}
            onSubmit={() => { dissmissDialog(); return Promise.resolve() }}
            isPreparing={isPreparing}>

                <p>
                    The following discount or uplift profiles are effective for the customer <strong>{loadedCustomer?.name} ({loadedCustomer?.id})</strong>. 
                    Please keep in mind that customer in a partner channel reflect the costs you as a serviceprovider charge the partner. The partner 
                    can define separate profiles for his customers.</p>   


                 <NavigationalDetailsListWithStandardOperations<ISdkCustomerDiscountProfile> 
                    uniqueIdentifier='discountprofileseffectiveroot' 
                    onBuildColumns={buildColumns} 
                    onLoadData={reloadData} 
                    hideReferesh={true} 
                    disabledItemSelect={true}
                    />
                 
        </PanelWithDefaultFooter>)
}
