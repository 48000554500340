import { IDropdownOption, Label, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IAppWindowBaseProps } from "../../ds/components/AppWindow";
import { ControlledCheckbox } from "../../forms/ControlledCheckbox";
import { ControlledDropdown } from "../../forms/ControlledDropdown";
import { ControlledFieldList } from "../../forms/ControlledFieldList";
import { ControlledFieldMappingList } from "../../forms/ControlledFieldMappingList";
import { ControlledFilterItemList } from "../../forms/ControlledFilterItemList";
import { ControlledTextField } from "../../forms/ControlledTextField";
import { IFieldItem } from "../../forms/UnControlledFieldItem";
import { IFieldMappingItem } from "../../forms/UnControlledFieldMappingItem";
import { IFilterItem } from "../../forms/UnControlledFilterItem";
import { ISdkReportDefinition, ISdkReportDefinitionCreateRequest, ISdkReportDefinitionTrigger } from "../../sdk/models/ISdkReportDefintion";
import { SdkTenantReportClient } from "../../sdk/SdkTenantReportClient";
import { PanelWithDefaultFooter } from "../DetailsListHelper/PanelWithDefaultFooter";
import { useEffectAsync } from "../Helpers/EffectAsyncHook";
import { getRelativeMonthDefinition } from "../Helpers/DateHelpers";
import { ReportDiscountProfileSelectorDialog } from "./ReportDiscountProfileSelectorDialog";
import { SdkTenantTriggerClient } from "../../sdk/SdkTenantTriggerClient";
import uuid from "react-uuid";
import { reportGenerateGroupedDatasourceOptions } from "./ReportSupportFunctions";
import { SdkTenantUserProfileClient } from "../../sdk/SdkTenantUserProfileClient";
import { ISdkPivotOperation } from "../../sdk/models/ISdkPivotOperation";

export interface IReportAddOrEditDialogProps extends IAppWindowBaseProps {
    tenantId: string
    reportCollectionType: "serviceProviderCollection" | undefined
    item?: ISdkReportDefinition
}

export const ReportAddOrEditDialog = (props: IReportAddOrEditDialogProps) => {

    const [isProcessing, setIsProcssing] = useState(false)
    const [isPreparing, setIsPreparing] = useState(false)    
    
    const [isAssignDiscountProfileOpen, setAssignDiscountProfileOpen] = useState(false)    
    const [currentFieldMapppingItem, setCurrentFieldMappingItem] = useState<IFieldMappingItem | undefined>(undefined)
    const [currentActiveDiscountProfiles, setCurrentActiveDiscountProfiles] = useState<string[]>([])

    const [datasources, setDatasources] = useState<ISdkReportDefinition[]>([])   
    const [availableFields, setAvailableFields] = useState<IDropdownOption[]>([]) 
    const [availableFieldTypes, setAvailableFieldTypes] = useState<IDropdownOption[]>([])
    const [availablePivotOperations, setAvailablePivotOperations] = useState<ISdkPivotOperation[]>([]) 
    const [availablePostProcessingFields, setAvailablePostProcessingFields] = useState<IDropdownOption[]>([]) 
    const [isPivotEnabled, setPivotEnabled] = useState(false)
    const [isTriggerAppearsWhenTriggerEnabled, setTriggerAppearsWhenTriggerEnabled] = useState(false)    
    const [availableTriggers, setAvailableTriggers] = useState<IDropdownOption[]>([])         
    const [isNotifyViaMailEnabled, SetNotifyViaMailEnabled] = useState(false)
    const [availableRecipients, setAvailableRecipients] = useState<IDropdownOption[]>([])     
        
    const [hasPendingReportChanges, setHasPrendingReportChanges] = useState<boolean>(false)
    

    const auth = useAuthInContext(props.tenantId);

    type AddReportBaseData = {
        id?: string
        name: string
        datasourceId: string,
        periodType: string,
        periodValue: string,
        dataFilter: IFilterItem[]
        dataFilterPost: IFilterItem[]
        fieldMappingItems:  IFieldMappingItem[]
        pivotEnabled: boolean
        pivotFields: IFieldItem[]
        triggerWhenDepedentReportChanged: boolean
        triggerManually: boolean,
        triggerWhenTriggerAppears: boolean,
        triggerWhenTriggerAppearsTrigger: string,
        discountProfileMap: { [key: string]: string[]}
        notifyViaMail: boolean
        notifyViaMailRecipients: IFieldItem[]

    }

    const initialValues: AddReportBaseData = {
        id: undefined,
        name: '',
        datasourceId: '',
        periodType: '',
        periodValue: '',
        dataFilter: [],
        dataFilterPost: [],
        fieldMappingItems: [],
        pivotEnabled: false,
        pivotFields: [],
        triggerWhenDepedentReportChanged: true,
        triggerManually: true,
        triggerWhenTriggerAppears: false,
        triggerWhenTriggerAppearsTrigger: '',
        discountProfileMap: {},
        notifyViaMail: false,
        notifyViaMailRecipients: []
    } 

    const { handleSubmit, control, reset, formState, watch, setValue, getValues } = useForm<AddReportBaseData>({
        defaultValues: initialValues,
        reValidateMode: "onSubmit",
        mode: "all"        
    });            
        
    const onDismissDialog = () => {

        if (isAssignDiscountProfileOpen) { return }

        setIsProcssing(false)
        setPivotEnabled(false)
        setTriggerAppearsWhenTriggerEnabled(false)
        SetNotifyViaMailEnabled(false)
        setAvailableRecipients([])

        reset(initialValues)

        if (props.dismissDialog)
            props.dismissDialog()
    }

    const onSubmit = () => {
                
        const submitHandler = handleSubmit((data: AddReportBaseData) => {
            
            // get the active datasource from teh data 
            var selectedDatasource = datasources.find((d) => d.id === data.datasourceId)
            if (!selectedDatasource) { return Promise.reject('No datasource found for the selected datasource id') }

            const mappedColumns = data.fieldMappingItems.map((item: IFieldMappingItem) => {

                // find the original field
                let originalField = selectedDatasource?.columns.find((c) => c.id === item.field)
                if (!originalField) { originalField = { id: '', name: item.field, type: 'string', dataSourceColumnId: '', pivotOperation: '', discountProfiles: [] }}
                                            
                // build the model
                return {
                    id: item.key as string,
                    name: (item.mappedField ? item.mappedField : originalField.name) as string,
                    type: originalField.type  as string,
                    dataSourceColumnId: item.field  as string,
                    pivotOperation: item.pivotOperation  as string,  
                    discountProfiles: data.discountProfileMap[item.key] ? data.discountProfileMap[item.key] : []                                     
                }
            })

            const mappedTriggers: ISdkReportDefinitionTrigger[] = []
            
            if (data.triggerWhenDepedentReportChanged) {
                mappedTriggers.push({
                    triggerType: 'Dependent',
                    triggerValue: selectedDatasource?.id || ''
                })
            }

            if (data.triggerManually) {
                mappedTriggers.push({
                    triggerType: 'Manual',
                    triggerValue: ''
                })
            }

            if (data.triggerWhenTriggerAppears) {
                mappedTriggers.push({
                    triggerType: 'Scheduled',
                    triggerValue: data.triggerWhenTriggerAppearsTrigger
                })
            }
            
            const mappedPivotFields = data.pivotFields.map((f) => f.field)

            const mappedDataFilters = data.dataFilter.map(f => {
                return {                        
                    operator: f.operator,
                    field: f.field,
                    value: f.value,
                    condition: f.condition,
                    compare: f.compare ? 'CaseInSensitive' : 'CaseSensitive'
                }
            })

            const mappedDataFiltersPost = data.dataFilterPost.map(f => {
                return {                        
                    operator: f.operator,
                    field: f.field,
                    value: f.value,
                    condition: f.condition,
                    compare: f.compare ? 'CaseInSensitive' : 'CaseSensitive'
                }
            })

            const notificationRecipients = data.notifyViaMailRecipients.map((item) => {
                return { 
                    userId: item.field, 
                    notificationTypes: ['email']
                }
            })

            const createRequest: ISdkReportDefinitionCreateRequest = {
                name: data.name,
                datasourceId: data.datasourceId,
                period: { periodType: data.periodType, periodValue: data.periodValue },
                columns: mappedColumns,
                triggers: mappedTriggers,
                pivotColumnIds: mappedPivotFields,
                dataFilters: mappedDataFilters,
                dataFiltersPost: mappedDataFiltersPost,
                locked: false,
                notificationRecipients: isNotifyViaMailEnabled ? notificationRecipients : []
            }

            // at this point we can create a new item and close the dialog
            const sdkClient = new SdkTenantReportClient(props.tenantId, props.reportCollectionType, auth.currentToken as string)

            if (data.id) {                
                return sdkClient.updateReportDefinition(data.id, createRequest)
            } else {
                return sdkClient.createReportDefinition(createRequest)
            }            
        })
        
        // enable processing state
        setIsProcssing(true)

        // handle the submit request
        return submitHandler()
        .then(() => { onDismissDialog() })
        .catch((error) => {
            let errorMessage = 'Something went wrong creating or updating report definition, please try again later'
            errorMessage += error.code ?  ' (' + error.code + ')' : ''            
            return errorMessage
        })
        .finally(() => { setIsProcssing(false) })   
    }    
    
    useEffectAsync(async () => {

        if (!props.isVisible) { return; }
        
        // Load the initial data
        setIsPreparing(true)
        const sdkClient = new SdkTenantReportClient(props.tenantId as string, props.reportCollectionType, auth.currentToken as string);
        
        // load the available pivot operations 
        const pivotOperations = await sdkClient.getPivotOperations()
        setAvailablePivotOperations(pivotOperations)

        // load the definitions
        const reportDefinitions = await sdkClient.getReportDefinitions()

        if (props.item) {
            setDatasources(reportDefinitions.filter((d) => d.id !== props.item?.id))        
        } else {
            setDatasources(reportDefinitions)
        }

        // load available triggers
        const sdkClientTriggers = new SdkTenantTriggerClient(props.tenantId as string, auth.currentToken as string);
        const triggers = await sdkClientTriggers.listTriggers()
        setAvailableTriggers(triggers.map((item) => { return { key: item.id, text: item.name } }))                

        // just react in case an item is set as well
        if (!props.item) {             
            reset({...initialValues, datasourceId: reportDefinitions[0].id}); 
            setIsPreparing(false)
            return 
        }
        
        // map the pivot fields
        let pivotFields: IFieldItem[] = [];
        if (props.item.pivotColumnIds && props.item.pivotColumnIds.length > 0) {
            pivotFields = props.item.pivotColumnIds.map(p => { return { key: p, field: p } })
            setPivotEnabled(true)
        }

        // the discount profile map
        const discountProfileMap: any = {}        

        // map the columns
        const mappedColumns: IFieldMappingItem[] = props.item.columns.map((c) => { 

            if (c.discountProfiles) {
                discountProfileMap[c.id] = c.discountProfiles
            } else {
                discountProfileMap[c.id] = []
            }

            return { 
                key: c.id, field: c.dataSourceColumnId, 
                mappedField: c.name, 
                pivotOperation: c.pivotOperation                
            } 
        })
        
        // map the trigger values 
        const triggerDependent = props.item.triggers.find((t) => t.triggerType === 'Dependent') ? true : false
        const triggerManual = props.item.triggers.find((t) => t.triggerType === 'Manual') ? true : false
        const triggerTrigger = props.item.triggers.find((t) => t.triggerType === 'Scheduled') ? true : false
        const triggerTriggerValue = props.item.triggers.find((t) => t.triggerType === 'Scheduled')?.triggerValue || ''
        
        // map the data filters
        const dataFilters = props.item.dataFilters.map((r, i) => { 
            return {
                key: 'datafilter' + i,
                operator: r.operator,
                field: r.field,
                condition: r.condition,
                value: r.value,
                compare: r.compare && r.compare === 'CaseInSensitive' ? true : false                        
            } 
        })

        // map the data filters
        const dataFiltersPost = !props.item.dataFiltersPost ? [] : props.item.dataFiltersPost.map((r, i) => { 
            return {
                key: 'datafilterpost' + i,
                operator: r.operator,
                field: r.field,
                condition: r.condition,
                value: r.value,
                compare: r.compare && r.compare === 'CaseInSensitive' ? true : false                        
            } 
        }) 

        // map the user notification recipients
        const notificationRecipients = []
        if (props.item.notificationRecipients && props.item.notificationRecipients.length > 0) {
            notificationRecipients.push(...props.item.notificationRecipients.map((item) => { return { key: item.userId, field: item.userId } }))
        } 

        reset({
            id: props.item.id,
            name: props.item.name,            
            datasourceId: props.item.datasourceId,
            periodType: props.item.period.periodType,
            periodValue: props.item.period.periodValue,
            dataFilter: dataFilters,
            dataFilterPost: dataFiltersPost,
            fieldMappingItems: mappedColumns,
            pivotEnabled: pivotFields.length > 0,
            pivotFields: pivotFields,
            triggerWhenDepedentReportChanged: triggerDependent,
            triggerManually: triggerManual,
            triggerWhenTriggerAppears: triggerTrigger,
            triggerWhenTriggerAppearsTrigger: triggerTriggerValue,
            discountProfileMap: discountProfileMap,
            notifyViaMail: notificationRecipients.length > 0,
            notifyViaMailRecipients: notificationRecipients       
        }) 
        
        // done
        setIsPreparing(false)        

    }, [props.isVisible])    

    // ensure we have the available fields ready when changed the datasource
    const [currentDataSourceId, currentPivtoEnabled, currentFieldMappingItems, currentPeriodType, currentTriggerWhenTriggerAppears, notifyViaMail] = watch(['datasourceId', 'pivotEnabled', 'fieldMappingItems', 'periodType', 'triggerWhenTriggerAppears', 'notifyViaMail'] )
            
    useEffect(() => {
        console.log('Update available fields as the current datasource changed to ' + currentDataSourceId + ' or all datasources are loaded (currently #' + datasources.length + ').')        

        // get the selected datasource
        var selectedDatasource = datasources.find((d) => d.id === currentDataSourceId)
        if (!selectedDatasource) { return }

        // get the available fields
        setAvailableFields(selectedDatasource.columns.map((item) => { return { key: item.id, text: item.name } }))        
        setAvailableFieldTypes(selectedDatasource.columns.map((item) => { return { key: item.id, text: item.type } }))        

    }, [currentDataSourceId, datasources])    

    useEffect(() => {
        setPivotEnabled(currentPivtoEnabled ? true : false)
    }, [currentPivtoEnabled])

    useEffect(() => {
        setTriggerAppearsWhenTriggerEnabled(currentTriggerWhenTriggerAppears ? true : false)
    }, [currentTriggerWhenTriggerAppears])
    
    useEffect(() =>  {
        setAvailablePostProcessingFields(currentFieldMappingItems.map((item) => { return { key: item.key, text: item.mappedField } }))
    }, [currentFieldMappingItems])

    useEffectAsync(async () => {

        if (notifyViaMail && availableRecipients.length === 0) {
            // read all users
            const sdkClient = new SdkTenantUserProfileClient(props.tenantId as string, auth.currentToken as string);
            const users = await sdkClient.listUserProfiles()

            // add the users as availabel recipients
            setAvailableRecipients(users.filter((item) => item.contactAddresses.length > 0 && !item.contactAddresses[0].endsWith('.local')).map((item) => { return { key: item.userId, text: item.firstName + ' ' + item.lastName + ' (' + item.contactAddresses[0] + ')' } }))
        } 

        // enable the drop down
        SetNotifyViaMailEnabled(notifyViaMail ? true : false)
    }, [notifyViaMail])

    const onSetllFields = () => {        
        const selectedDatasource = datasources.find((d) => d.id === currentDataSourceId)
        if (!selectedDatasource) { return }

        const mappedColumns = selectedDatasource.columns.map((item) => {
            return {
                key: item.id,
                field: item.id,
                mappedField: item.name,
                pivotOperation: currentPivtoEnabled ? 'first' : 'none'
            }
        })

        setValue('fieldMappingItems', mappedColumns)            
    }

    const onAddCalculatedField = () => {        

        const calculatedField = {
            key: uuid(),
            field: '',
            mappedField: '',
            pivotOperation: '$calculated'
        }

        setValue('fieldMappingItems', [...getValues().fieldMappingItems, calculatedField])
    }

    const onAssignDiscountProfile = (item: IFieldMappingItem) => {
        setCurrentFieldMappingItem(item)

        const localDiscountProfileMap: any = getValues().discountProfileMap
        if (!Object.keys(localDiscountProfileMap).includes(item?.key as string)) { 
            localDiscountProfileMap[item?.key as string] = []
        }

        setCurrentActiveDiscountProfiles(localDiscountProfileMap[item?.key as string])

        setAssignDiscountProfileOpen(true)
    } 

    const onAssignDiscountProfileChanged = (disocuntProfiles: string[]) => {
        const localDiscountProfileMap: any = getValues().discountProfileMap
        if (!Object.keys(localDiscountProfileMap).includes(currentFieldMapppingItem?.key as string)) { 
            localDiscountProfileMap[currentFieldMapppingItem?.key as string] = []
        }

        localDiscountProfileMap[currentFieldMapppingItem?.key as string] = disocuntProfiles
        setValue('discountProfileMap', localDiscountProfileMap)        
    }

    const onItemsPending = (pending: boolean): void => {
        setHasPrendingReportChanges(pending)
    }


    let periodTypeOptions: IDropdownOption[] = [
        { key: 'Current', text: 'Current Month' },
        { key: 'LastFinished', text: 'Last Month' },
        { key: 'Dedicated', text: 'Specific Month' }        
    ]    
    
    const periodValueOptions: IDropdownOption[] = [{key: 'automatic', text: 'n/a'}]
    for(let i = -60; i <= 60; i++) {

        const monthString = getRelativeMonthDefinition(i);

        periodValueOptions.push({
            key: monthString,
            text: monthString
        })
    }    
        
    const onFilterAvailablePivotOperations = (propertyName: string, availableOptions: IDropdownOption[]) => {
        
        // get the active datasource
        const selectedDatasource = datasources.find((d) => d.id === currentDataSourceId)
        if (!selectedDatasource) { return [] }

        // get the active column
        const activeColumn = selectedDatasource?.columns.find((c) => c.id === propertyName)
        if (!activeColumn) { return [] }

        // cast the availabe options to the pivot operations
        const availablePivotOperations = availableOptions as ISdkPivotOperation[]
        if (!availablePivotOperations) { return [] }

        // sanitize data type 
        const dataTypeToCheck = activeColumn.type.toLowerCase().replace(' ', '')

        // check if the given data type is part of the supported ones
        return availablePivotOperations.filter((pivotOperation) => pivotOperation.supportedDataTypes.includes("*") || pivotOperation.supportedDataTypes.includes(dataTypeToCheck))        
    }
    
    return (
        <>
            <PanelWithDefaultFooter
                isOpen={props.isVisible}
                headerText={props.item ? 'Modify report definition' : 'Create a new report definition'}
                onDismiss={onDismissDialog}
                onSubmit={onSubmit}
                type={PanelType.large} 
                progessMessage={'Storing report definition'} 
                submitLabel={props.item ? 'Update' : 'Create'} 
                dissmissLabel={'Cancel'} 
                isValid={formState.isValid && formState.isDirty}
                doubleConfirmMessage={hasPendingReportChanges ? 'Some changes are pending, please verify the check mark buttons before continue' : undefined}>

                { isPreparing ? (
                        <Spinner size={SpinnerSize.large} label="Loading data..." labelPosition="bottom" style={{height: '100%'}}/>
                    ) : (
                    <div>
                        <p>Please enter all mandatory information to create a new or update 
                        an existing report. The report will be available for in all dashboards</p> 

                        <form>
                            <Stack tokens={{ childrenGap: 15 }}>                
                                <ControlledTextField label={'Name'} control={control} name={'name'} rules={{ required: 'a valid valud is required'}} disabled={isProcessing} />
                                <ControlledDropdown label={'Datasource'} control={control} name={"datasourceId"} options={reportGenerateGroupedDatasourceOptions(datasources)} />                                                        
                            </Stack>                    
                        </form>     

                        <div>
                            <Stack tokens={{ childrenGap: 15 }} style={{marginTop: '15px'}}>  
                                <Label>Report Period</Label>                             
                                <ControlledDropdown key={'periodType'} control={control} name={'periodType'} disabled={isProcessing} options={periodTypeOptions} />
                                <ControlledDropdown key={'periodValue'} control={control} name={'periodValue'} disabled={isProcessing || currentPeriodType !== 'Dedicated'} options={periodValueOptions} />                            
                            </Stack>
                        </div>

                        <div>
                            <Stack tokens={{ childrenGap: 15 }} style={{marginTop: '15px'}}>  
                                <Label>Report Trigger</Label> 
                                <ControlledCheckbox key={'triggerManually'} control={control} name={'triggerManually'} disabled={isProcessing} label="Allow execution manually" />
                                <ControlledCheckbox key={'triggerWhenDepedentReportChanged'} control={control} name={'triggerWhenDepedentReportChanged'} disabled={isProcessing} label="Generate when dependent report (datasource) changed" />                            
                                <ControlledCheckbox key={'triggerWhenTriggerAppears'} control={control} name={'triggerWhenTriggerAppears'} disabled={isProcessing} label="Generate when referenced trigger appears" />                            
                                <ControlledDropdown placeholder={'Select available trigger'} control={control} name={'triggerWhenTriggerAppearsTrigger'} options={availableTriggers} disabled={isProcessing || !isTriggerAppearsWhenTriggerEnabled }/>                                                        
                            </Stack>
                        </div>
                        <div>
                            <Stack tokens={{ childrenGap: 15 }} style={{marginTop: '15px'}}>  
                                <Label>Report Notifications</Label> 
                                <ControlledCheckbox key={'notifyViaMail'} control={control} name={'notifyViaMail'} disabled={isProcessing} label="Send scheduled report via E-Mail" />                                
                                <ControlledFieldList control={control} name={'notifyViaMailRecipients'} placeholder={'Select a recipient'} availableFields={availableRecipients} allowMappedFields={true} availableFieldTypes={[]} disabled={isProcessing || !isNotifyViaMailEnabled } />                                                        
                            </Stack>
                        </div>
                        <div>
                            <Label style={{marginTop: '10px'}}>Report Pre-Processing Data Filter (optional)</Label>
                            <ControlledFilterItemList control={control} name={'dataFilter'} availableFields={availableFields} disabled={isProcessing} />                            
                        </div>             
                        <div>
                            <Stack tokens={{ childrenGap: 15 }} style={{marginTop: '15px'}}>  
                                <Label>Report Pivot Settings</Label> 
                                <ControlledCheckbox key={'pivot'} control={control} name={'pivotEnabled'} disabled={isProcessing} label="Enable" />
                                <ControlledFieldList control={control} name={'pivotFields'} availableFields={availableFields} allowMappedFields={true} availableFieldTypes={availableFieldTypes} disabled={isProcessing || !isPivotEnabled } />                                                        
                            </Stack>                                                
                        </div>
                        <div>
                            <div style={{display: 'flex', marginTop: '15px', justifyContent: 'space-between', width: '100%'}}>
                                <Label style={{marginTop: '10px'}}>Report Fields</Label>
                                <div style={{display: 'flex', justifyContent: 'end'}}>
                                    <PrimaryButton iconProps={{ iconName: 'faPlus'}} onClick={onAddCalculatedField} style={{marginRight: '10px'}} >Calculated Field</PrimaryButton>
                                    <PrimaryButton onClick={onSetllFields}>Set all fields</PrimaryButton>
                                </div>
                            </div>
                            <ControlledFieldMappingList control={control} name={'fieldMappingItems'} availableFields={availableFields} disabled={isProcessing} defaultPivotKey={isPivotEnabled ? 'first' : undefined} availablePivotOperations={availablePivotOperations} onFilterAvailablePivotOperations={onFilterAvailablePivotOperations} onElipsisClicked={props.reportCollectionType === 'serviceProviderCollection' ? onAssignDiscountProfile : undefined } onItemsPending={onItemsPending} />                            
                        </div>                         
                        <div>
                            <Label style={{marginTop: '10px'}}>Report Post-Processing Data Filter (optional)</Label>
                            <ControlledFilterItemList control={control} name={'dataFilterPost'} availableFields={availablePostProcessingFields} disabled={isProcessing} />                            
                        </div>                                                                
                    </div>
                )}
                                    
            </PanelWithDefaultFooter>         
            
            <ReportDiscountProfileSelectorDialog 
                tenantId={props.tenantId} isVisible={isAssignDiscountProfileOpen} 
                referenceReportId={watch('datasourceId')}                 
                dismissDialog={() => { setCurrentFieldMappingItem(undefined); setCurrentActiveDiscountProfiles([]); setAssignDiscountProfileOpen(false)}} 
                activeDiscountProfiles={currentActiveDiscountProfiles}                
                onChange={onAssignDiscountProfileChanged}
                fieldId={currentFieldMapppingItem?.field as string}
                fieldName={currentFieldMapppingItem?.mappedField as string}
                />
        </>
    )    
}